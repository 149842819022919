const mollieKey = process.env.VUE_APP_MOLLIE_API_KEY || 'pfl_f4pWt6fkDk'
let testMode = true

if(window.location.hostname == 'app.octoa.com'){
  testMode = false
}

const mollie = Mollie(
  mollieKey, // You can find your Profile ID in the Dashboard (https://www.mollie.com/dashboard/developers/api-keys)
  {
    locale: 'en_US', // Optional. If not provided, we will determine the users' language by looking at the document and/or userAgent.
    testmode: testMode // Set to true to enable test mode.
  }
)

export default {
  orgin: mollie,
  cardHolder: mollie.createComponent('cardHolder'),
  cardNumber: mollie.createComponent('cardNumber'),
  expiryDate: mollie.createComponent('expiryDate'),
  verificationCode: mollie.createComponent('verificationCode'),
}
