<style lang="scss">
  .select-box{
    border:1px solid #d1d1d1;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
    border-radius:4px;
    @apply text-center;
    color:#000;
    padding:20px;
    margin: 0 auto;
    width:330px;

    .sticker{
      position:absolute;
      top:-25px;
      left:-25px;
      background:#FAB413;
      border-radius:100%;
      padding:18px 12px;
      font-size:12px;
      font-weight:900;
      transform:rotate(-30deg);
      text-align:center;
    }

    .green-btn{
      padding-right:30px;
      padding-left:30px;
    }

    &.active{
      border: 2px solid #FAB413;
    }
    ul{
      text-align:left;
      font-size:16px;
      margin-left:45px;
      li{
        margin-bottom:10px;
      }
    }
  }
  .history{
    border:1px solid #D1D1D1;
    border-radius:4px;
    .header{
      background:#FBFBFB;
      padding:10px 15px;
      border-radius:4px 4px 0 0;
      border-bottom:1px solid #EDEDED;
    }
    .billing{
      display:flex;
      border-bottom:1px solid #EDEDED;
      padding:15px;
      &:last-child{
        border:none;
      }
      &.hidden{
        display:none;
      }
    }

    .showMore{
      //border-top:1px solid #EDEDED;
    }
  }
</style>
<template>
  <div
    class="py-10 bg-grey-light min-h-full"
  >
    <vue-headful
      title="Reactivate your plan | Octoa"
    />
    <form
      action="#"
      class="mx-auto mollie"
      @submit.prevent="upgrade"
    >
      <div v-if="isAuth">
        <div class="w-full max-w-md mx-auto">
          <div class="text-center">
            <img
              src="@/assets/img/octoa.svg"
              width="60"
              class="my-10"
            >
            <h1>Hello, {{ user.company.name }}.</h1>
            <br>
            <span class="text-xl  underline bg-blue-washed px-1 py-1">It looks like your subscription has been expired! </span>
          </div>
          <div class="text-xl text-grey-darker mt-5">
            <div class="flex flex-wrap mb-5">
              <div class="w-full px-2">
                <div class="bg-white h-full mx-auto max-w-md px-8 py-8 border-grey-lighter border rounded">
                  <h2 class="font-bold mb-2 text-center">
                    Reactivate to enjoy full features of Octoa
                  </h2>
                  <div class="flex mt-2">
                    <div class="radio-container mt-4">
                      <input
                        id="annually"
                        v-model="form.plan"
                        type="radio"
                        value="annually"
                        name="plan"
                        @change="onPlanChange"
                      >
                      <div class="border">
                        <label
                          for="annually"
                          class="radio-label cursor-pointer"
                        >
                          <div class="flex w-full justify-between">
                            <div>
                              Billed yearly
                              <div class="sub-text">You'll save 20%</div>
                            </div>
                            <div class="text-right">
                              <strong class="text-black">€210/year</strong>  <br>
                              <span class="text-grey-semi-light text-sm">per year</span>
                            </div>
                          </div>
                        </label>
                        <div class="check" />
                      </div>
                    </div>
                  </div>

                  <div class="flex mt-2">
                    <div class="radio-container mt-4">
                      <input
                        id="monthly"
                        v-model="form.plan"
                        type="radio"
                        value="monthly"
                        name="plan"
                        @change="onPlanChange"
                      >
                      <div class="border">
                        <label
                          for="monthly"
                          class="radio-label cursor-pointer"
                        >
                          <div class="flex w-full justify-between">
                            <div>
                              Billed monthly
                              <div class="sub-text">Cancel any time</div>
                            </div>
                            <div class="text-right">
                              <strong class="text-black">€21</strong> <br>
                              <span class="text-grey-semi-light text-sm">per month</span>
                            </div>
                          </div>
                        </label>
                        <div class="check" />
                      </div>
                    </div>
                  </div>
  <!--                 <div class="text-center text-sm mt-2">You next billing date will be on {{ nextBillingDate }}.</div>
   -->                <div class="text-xl mt-2">
  <div class="w-full flex flex-wrap mt-8">
                      <div class="w-full text-base font-bold text-black">
  Credit card details
  </div>
                      <div class="mollie flex flex-wrap w-full mt-3">
                        <div
                          id="card-holder-group"
                          class="w-full  form-group form-group--cardHolder"
                        >
                          <label
                            class="label"
                            for="card-holder"
                          >Card holder</label>
                          <div id="card-holder" />
                          <div
                            id="card-holder-error"
                            class="field-error"
                            role="alert"
                          />
                        </div>

                        <div class="mt-3   w-full form-group form-group--cardNumber">
                          <label
                            class="label"
                            for="card-number"
                          >Card number</label>
                          <div id="card-number" />
                          <div
                            id="card-number-error"
                            class="field-error"
                            role="alert"
                          />
                        </div>
                        <div class="w-1/2 mt-3  form-group form-group--expiryDate">
                          <label
                            class="label"
                            for="expiry-date"
                          >MM / YY</label>
                          <div
  id="expiry-date"
  class="pr-4"
  />
                          <div
                            id="expiry-date-error"
                            class="field-error"
                            role="alert"
                          />
                        </div>
                        <div class="w-1/2 mt-3 form-group form-group--verificationCode">
                          <label
                            class="label"
                            for="verification-code"
                          >CVV</label>
                          <div id="verification-code" />
                          <div
                            id="verification-code-error"
                            class="field-error"
                            role="alert"
                          />
                        </div>
                      </div>
                      <div class="mt-5 w-full text-base font-bold text-black">
  Billing details
  </div>
                      <div class="w-full flex flex-wrap mt-3">
                        <div class="w-full">
                          <input
                            id="name"
                            ref="name"
                            v-model="form.name"
                            v-validate="{ required: true }"
                            class="w-full"
                            type="text"
                            name="name"
                            placeholder="Name"
                          >
                          <span
                            v-if="errors.has('name')"
                            class="error"
                          >{{ errors.first('name') }}</span>
                        </div>
                        <div class="w-full mt-3">
                          <input
                            id="addres"
                            ref="address"
                            v-model="form.address"
                            v-validate="{ required: true }"
                            name="address"
                            class="w-full"
                            type="text"
                            placeholder="Address"
                          >
                          <span
                            v-if="errors.has('address')"
                            class="error"
                          >{{ errors.first('address') }}</span>
                        </div>
                        <div class="w-1/2 mt-3 pr-4">
                          <input
                            id="postal_code"
                            ref="postal_code"
                            v-model="form.postal_code"
                            v-validate="{ required: true }"
                            class="w-full"
                            name="postal"
                            type="text"
                            placeholder="Postal code"
                          >
                            <span
                            v-if="errors.has('postal')"
                            class="error"
                          >{{ errors.first('postal') }}</span>
                        </div>
                        <div class="w-1/2 mt-3">
                          <input
                            id="city"
                            ref="city"
                            v-model="form.city"
                            v-validate="{ required: true }"
                            class="w-full"
                            name="city"
                            type="text"
                            placeholder="City"
                          >
                          <span
                            v-if="errors.has('city')"
                            class="error"
                          >{{ errors.first('city') }}</span>
                        </div>
                        <div class="w-full mt-3">
                          <div class="select-container w-full">
                            <select
                              v-model="form.country"
                              v-validate="'required'"
                              name="country"
                              :tab-index="0"
                              @change="countryChange"
                            >
                              <option value="">
                                Select a country
                              </option>
                              <option
                                v-for="(item, index) in countriesList"
                                :key="index"
                                :value="item.name"
                              >
                                {{ item.name }}
                              </option>
                            </select>
                          </div>
                          <span
v-if="errors.has('country')"
class="error"
>{{ errors.first('country') }}</span>
                        </div>
                        <div class="w-full mt-3">
                          <input
                            id="vat"
                            ref="vat"
                            v-model="form.vat"
                            class="w-full"
                            name="vat"
                            type="text"
                            placeholder="VAT number (optional)"
                            @input="onCheckVat"
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    v-if="!loading"
                    class="green-btn mt-5 w-full rounded"
                  >
                    Reactivate my account
                  </button>

                  <div
                    v-if="loading"
                    class="text-center mt-5"
                  >
                    <img
                      src="@/assets/img/icons/loader.svg"
                      width="35"
                      class="text-center"
                    >
                  </div>

                  <div class="mt-5 mb-2 text-sm">
                    <div
                      v-if="timezone"
                      class="block text-center"
                    >
                      <strong>You'll be charged €<span class="price-text">{{ priceText }}/{{ pricePeriod }}<span v-if="form.includeVat">&nbsp;(including VAT)</span></span></strong>.
                    </div>
                  </div>

                  <div class="mt-5 mb-2 text-grey-semi-light text-sm text-center">
                    Got a questions? Contact us at <a
                      class="text-green underline"
                      href="mailto:hello@octoa.com"
                    >hello@octoa.com</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import auth from '@/auth'
import mollie from '@/mollie'
import { isBefore, addMonths, addYears } from 'date-fns'
import { debounce } from '@/utils/helpers'

export default {
  name: 'ReactivatePlan',
  data(){
    return {
      today: new Date(),
      loading: false,
      isAuth: auth.check(),
      user: auth.user(),
      priceText: '',
      pricePeriod: 'year',
      vatNumberValid: false,
      timezone: '',
      countriesList: {},
      nextBillingDate: new Date(),
      form:{
          plan: 'annually',
          token: '',
          name: '',
          address: '',
          postal_code: '',
          city: '',
          country: '',
          vat: '',
          includeVat: false,
        }
    }
  },
  beforeDestroy(){
    mollie.cardHolder.unmount('#card-holder')
    mollie.cardNumber.unmount('#card-number')
    mollie.expiryDate.unmount('#expiry-date')
    mollie.verificationCode.unmount('#verification-code')
  },
  async mounted() {

    if(!this.user){
      this.$router.push('/login?redirect=%2Fstart')
      return false
    }

    auth.refreshUser()
    if(this.user.company.is_subscription_active && (!this.user.company.subscription_end_at || isBefore(this.today, this.user.company.subscription_end_at))){
      this.$router.push('/')
    }

    await this.getCountriesList()

    this.form.name = this.user.company.name
    this.form.address = this.user.company.address
    this.form.postal_code = this.user.company.postal_code
    this.form.city = this.user.company.city
    this.form.country = this.user.company.country
    this.form.vat = this.user.companySettings.vat
    this.timezone = this.user.companySettings.timezone

    this.nextBillingDate = addYears(new Date(), 1)
    this.nextBillingDate = this.$options.filters.humanDate(this.nextBillingDate, this.timezone)

     debounce(async () => {
      await this.checkVat(this.form.country)
    }, 500)()

    mollie.cardHolder.mount('#card-holder')
    const cardHolderError = document.getElementById('card-holder-error')
    mollie.cardHolder.addEventListener('change', event => {
      if (event.error && event.touched) {
        cardHolderError.textContent = event.error
      } else {
        cardHolderError.textContent = ''
      }
    })

    mollie.cardNumber.mount('#card-number')
    const cardNumberError = document.getElementById('card-number-error')
    mollie.cardNumber.addEventListener('change', event => {
      if (event.error && event.touched) {
        cardNumberError.textContent = event.error
      } else {
        cardNumberError.textContent = ''
      }
    })


    mollie.expiryDate.mount('#expiry-date')
    const expiryDateError = document.getElementById('expiry-date-error')

    mollie.expiryDate.addEventListener('change', event => {
      if (event.error && event.touched) {
        expiryDateError.textContent = event.error
      } else {
        expiryDateError.textContent = ''
      }
    })

    mollie.verificationCode.mount('#verification-code')
      const verificationCodeError = document.getElementById('verification-code-error')

      mollie.verificationCode.addEventListener('change', event => {
        if (event.error && event.touched) {
          verificationCodeError.textContent = event.error
        } else {
          verificationCodeError.textContent = ''
        }
      })

      function toggleFieldDirtyClass(fieldName, dirty) {
        const element = document.getElementById(fieldName)
        element.parentNode.classList.toggle('is-dirty', dirty)
      }

      function toggleFieldFocusClass(fieldName, hasFocus) {
        const element = document.getElementById(fieldName)
        element.parentNode.classList.toggle('has-focus', hasFocus)
      }

      mollie.cardHolder.addEventListener('change', event => toggleFieldDirtyClass('card-holder', event.dirty))
      mollie.cardHolder.addEventListener('focus', () => toggleFieldFocusClass('card-holder', true))
      mollie.cardHolder.addEventListener('blur', () => toggleFieldFocusClass('card-holder', false))

      mollie.cardNumber.addEventListener('change', event => toggleFieldDirtyClass('card-number', event.dirty))
      mollie.cardNumber.addEventListener('focus', () => toggleFieldFocusClass('card-number', true))
      mollie.cardNumber.addEventListener('blur', () => toggleFieldFocusClass('card-number', false))

      mollie.expiryDate.addEventListener('change', event => toggleFieldDirtyClass('expiry-date', event.dirty))
      mollie.expiryDate.addEventListener('focus', () => toggleFieldFocusClass('expiry-date', true))
      mollie.expiryDate.addEventListener('blur', () => toggleFieldFocusClass('expiry-date', false))

      mollie.verificationCode.addEventListener('change', event => toggleFieldDirtyClass('verification-code', event.dirty))
      mollie.verificationCode.addEventListener('focus', () => toggleFieldFocusClass('verification-code', true))
      mollie.verificationCode.addEventListener('blur', () => toggleFieldFocusClass('verification-code', false))

    this.updatePriceDisplay()
  },
  methods: {
    async onCheckVat(){
      debounce(async () => {
        await this.checkVat(this.form.country)
      }, 1000)()
    },
    async checkVat(countryName){
      let that = this
      this.vatNumberValid = false

      const http = new XMLHttpRequest()
      const url = `https://apilayer.net/api/validate?access_key=0e1bcc6d3f622b4ed1111f86e61db3c9&vat_number=${this.form.vat}&format=1`
      http.open('GET', url)
      http.send()

      http.onreadystatechange = function(data){
        if(data.target.readyState == 4 && data.target.status == 200){
          let response = JSON.parse(data.target.response)
          that.vatNumberValid = response.valid
        }

        that.form.includeVat = false
        that.countriesList.forEach(country => {
          if(country.name == that.form.country){
            if(country.name == 'Netherlands' || (country.is_european_union && !that.vatNumberValid)){
              that.form.includeVat = true
            }
          }
        })

        that.updatePriceDisplay() // Extract price update logic to separate method
      }
    },
    updatePriceDisplay() {
      const isMonthly = this.form.plan === 'monthly'

      if(this.form.includeVat){
        this.priceText = isMonthly ? '25.41' : '254.10'
      } else {
        this.priceText = isMonthly ? '21.00' : '210.00'
      }
      this.pricePeriod = isMonthly ? 'month' : 'year'
    },
    async getCountriesList() {
      try {
        const { data } = await this.$api.get('country').all()
        this.countriesList = data.countries
      } catch (e) {
        this.$toasted.global.api_error(e)
      }
    },
    async countryChange(event){
      debounce(async () => {
        await this.checkVat(event.target.value)
      }, 300)()
    },
    onPlanChange(plan){
      this.updatePriceDisplay()
    },
    async refresh() {
        this.loading = false
      },
      async upgrade(){
        const { token, error } = await mollie.orgin.createToken()
        this.form.token = token

        this.$validator.validate().then(result => {
          if(result){
            this.submit()
          }
        })
      },
      async submit(){

        if(this.form.token !== undefined){
          this.loading = true
          this.$toasted.clear()

          try {
            const { data } =   await this.$api.get('subscription').reactivate(this.user.company.id, this.form)
            await this.$store.dispatch('auth/refreshUser')

            if(data._links.checkout.href){
              window.location.href = data._links.checkout.href
            } else{
              await this.refresh()
              this.$router.push('/settings/plan-and-billing')
            }
          } catch (e) {
            this.$toasted.global.api_error(e)
          }

          this.loading = false
        }

        this.$router.push('/')

      },
  },
}
</script>
